import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';
import {
  ActivitySectionDirective,
  FileCacheService,
  fromArray,
  IIntegrationKelseysMenuGlobalCategory,
  NetworkService,
  UserActivityClickTrackingDirective,
} from '@core';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { MenuCategoryComponent } from '@app/components/menu-category/menu-category.component';
import { IMenuCategory, IProducts } from '@app/models';
import { NewMenuStorageService } from '@app/services/new-menu-storage.service';
import { NewMenuApiService } from '@app/services/new-menu-api.service';
import { NewMenuSyncService } from '@app/services/new-menu-sync.service';

@UntilDestroy()
@Component({
  selector: 'app-menu-tab',
  standalone: true,
  templateUrl: './menu-tab.component.html',
  imports: [
    NgIf,
    NgForOf,
    LoadingComponent,
    MenuCategoryComponent,
    NgStyle,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./menu-tab.component.scss'],
})

export class MenuTabComponent implements OnInit {

  public categories: IMenuCategory[] | [] = [];
  public currentCategory!: IMenuCategory;
  public isCategoryOpen = false;
  public isProductOpen = false;
  public currentProduct!: IProducts;
  public gCatId: number | null = null;
  public gCategories!: IIntegrationKelseysMenuGlobalCategory[];
  public isLoading = true;

  constructor(
    private menuStorage: NewMenuStorageService,
    private networkConnection: NetworkService,
    private filesCache: FileCacheService,
    private domSanitizer: DomSanitizer,
    private menuApi: NewMenuApiService,
    private syncService: NewMenuSyncService,
  ) {
    this.syncService.init();

    this.gCategories = this.menuApi.globalCategories$.getValue();
    this.menuApi.globalCategories$.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
      filter((menuGCategory: IIntegrationKelseysMenuGlobalCategory[]) => menuGCategory && menuGCategory.length > 0),
    ).subscribe((gCategory) => {
      this.gCatId = gCategory.length > 0 ? gCategory[0].hierarchyId : null;
    });
  }

  get isOnline(): boolean {
    return this.networkConnection.status$.getValue();
  }

  public ngOnInit(): void {
    this.isLoading = this.menuStorage.isLoading.getValue();
    this.menuStorage.isLoading.pipe(
      untilDestroyed(this),
      filter((loading: boolean) => !loading),
      take(1),
    ).subscribe(() => {
      if (this.gCatId) {
        this.setActive(this.gCatId);
      }
    });
  }

  public showCategory(category: IMenuCategory): void {
    this.currentCategory = category;
    this.isCategoryOpen = true;
  }

  public showProduct(product: IProducts): void {
    this.currentProduct = product;
    this.isProductOpen = true;
  }

  public isActiveSection(hierarchyId: number): boolean {
    return this.gCatId === hierarchyId;
  }

  public setActive(hierarchyId: number): void {
    this.isCategoryOpen = false;
    this.categories = [];
    this.gCatId = hierarchyId;
    this.menuStorage.getCategoriesByHierarchyId(hierarchyId).pipe(
      switchMap((categories: IMenuCategory[]) => {
        return fromArray(categories).pipe(
          mergeMap((category) => {
            if (typeof category.bgSku === 'string' && category.hierarchyId !== 735) {
              return this.filesCache.getFile(category.bgSku).pipe(
                map(file => this.domSanitizer.bypassSecurityTrustUrl(file?.objectUrl)),
                map(file => (
                  { ...category, sanitizedUrl: file }
                )),
              );
            }
            return of(category);
          }),
          toArray(),
        );
      }),
      untilDestroyed(this),
    ).subscribe(category => {
      if (category.length === 1) {
        this.showCategory(category[0]);
        this.isCategoryOpen = true;
        this.isLoading = false;
      }
      else {
        this.categories = category;
        this.isLoading = false;
      }
    });
  }

  public getDefaultBg(category: IMenuCategory): string {
    const foundCategory = this.gCategories.find(cat => cat.hierarchyId === category.hierarchyId);
    return foundCategory ? foundCategory.defaultCategoryBgImage ?? '' : '';
  }

}
