@if (!isSolo) {
  <button
    class="header"
    type="button"
    [class.stuck]="headerSticky()"
    (click)="emitClose()"
    coreUserActivityClickTracking="CloseCategory"
  >
    <img class="back-navigation" src="/images/back.svg" alt="">
    <div class="category-name">{{ category.categoryName }}</div>
  </button>
}

<div class="sub_categories" [class.solo]="isSolo">
  @for (subCategory of subCategories; track subCategory.id) {
    <div class="sub_category">
      <h3>{{subCategory.categoryName}}</h3>
      <div class="sub_category__products">
        @for (product of subCategory.products; track product.id) {
          <app-product-preview
            class="product"
            [product]="product"
          ></app-product-preview>
        }
      </div>
    </div>
  }
</div>
