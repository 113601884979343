<div class="content" #content coreActivitySection="Special">
  <div class="back-navigation" routerLink="/main/home" coreUserActivityClickTracking="CloseSpecial">
    <img src="/images/back.svg" alt="">
  </div>
  <div class="indicators">
    <ng-container *ngFor="let slide of indicators; let i = index">
      <div class="indicator" [class.active]="indicators.length > 3 ? i === this.index : (i === this.index || i === this.index- indicators.length)">
      </div>
    </ng-container>
  </div>

  <app-loading *ngIf="isLoading;"></app-loading>

  <div class="slider">
    <ng-container *ngIf="slides.length && slides.length > 1 else one">
      <ng-container *ngFor="let slide of slides; let i = index">
        <img
          [src]="slide"
          class="slide"
          [class.previous]="shouldShowPrevious(i)"
          [class.next]="shouldShowNext(i)"
          [@slideAnimation]="getAnimationState(i)"
          *ngIf="shouldShowPrevious(i) || shouldShowNext(i) || i === this.index"
          alt="slide">
      </ng-container>
    </ng-container>

    <ng-template #one>
      <img *ngIf="slides.length" [src]="slides[0]" class="slide" alt="slide">
      <div class="empty" *ngIf="!slides.length && !isLoading">
        {{ 'promo.empty' | translate }}
      </div>
    </ng-template>
  </div>
</div>

