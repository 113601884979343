import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileCacheService } from '@core';
import { NgForOf, NgIf } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';
import { IProducts } from '@app/models';

@UntilDestroy()
@Component({
  selector: 'app-product-preview',
  standalone: true,
  templateUrl: './product-preview.component.html',
  imports: [
    NgIf,
    LoadingComponent,
    NgForOf,
  ],
  styleUrls: ['./product-preview.component.scss'],
})
export class ProductPreviewComponent implements OnInit {
  public loading = true;
  public hesImagError = false;

  @Input() product!: IProducts;

  public productImage: SafeUrl | undefined;

  constructor(
    private readonly filesCache: FileCacheService,
    private readonly domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    if (typeof this.product.sku === 'string') {
      this.filesCache.getFile(this.product.sku).pipe(
        map((file) => this.domSanitizer.bypassSecurityTrustUrl(file.objectUrl)),
        untilDestroyed(this),
      ).subscribe((file) => {
        this.productImage = file;
      });
    }
  }

  onImageError($event: ErrorEvent): void {
    if ($event) {
      this.hesImagError = true;
    }
  }

  onLoad(): void {
    this.loading = false;
  }

}
