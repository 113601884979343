<div class="section spinach" (click)="setState('spinach')" coreUserActivityClickTracking="SpinechOpen">
  <img class="section__image" src="/images/freeSpinachDip.jpg" alt="spinech">
  <span class="section__name">Free Spinach Dip</span>
</div>

<div class="section cards" (click)="setState('cards')" coreUserActivityClickTracking="CardsOpen">
  <img class="section__image" src="/images/giftCards.jpg" alt="cards">
  <span class="section__name">Gift Cards</span>
</div>

<div class="section events" (click)="setState('events')" coreUserActivityClickTracking="EventsOpen">
  <img class="section__image" src="/images/groupEvents.jpg" alt="events">
  <span class="section__name">Group Events & Parties</span>
</div>

<div class="section kids" (click)="setState('kids')" coreUserActivityClickTracking="KidsOpen">
  <img class="section__image" src="/images/kids.jpg" alt="kids">
  <span class="section__name">Kids Zone</span>
</div>

<div class="section social" (click)="setState('app')" coreUserActivityClickTracking="AppOpen">
  <img class="section__image" src="/images/kelseyApp.jpg" alt="app">
  <span class="section__name">Kelseys APP</span>
</div>

<div class="section social" (click)="setState('social')" coreUserActivityClickTracking="SocialOpen">
  <img class="section__image" src="/images/socials.jpg" alt="social">
  <span class="section__name">Social</span>
</div>

<app-kelseys-more-section
  *ngIf = "!isSections"
  @InOutAnimation
  (backToSections) = closeSection($event)
  [data] = "sectionData"
  [coreActivitySection] = this.state
></app-kelseys-more-section>
