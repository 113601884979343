import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  CORE_API_BASE_URL,
  IIntegrationKelseyMenu,
  IIntegrationKelseysMenuGlobalCategory,
  RestaurantTableService,
} from '@core';

interface IProxyConfig {
  apiBasePath: string;
  storeNumber: number;
  lang: string;
  priceType: string;
  proxyApiProvider: string;
  proxyCdnProvider: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NewMenuApiService {

  public readonly globalCategories$ = new BehaviorSubject<IIntegrationKelseysMenuGlobalCategory[]>([]);
  private readonly config$ = new BehaviorSubject<IProxyConfig>({
    apiBasePath: '',
    storeNumber: 0,
    lang: '',
    priceType: '',
    proxyApiProvider: '',
    proxyCdnProvider: 'kelseys-menu-cdn',
  });

  constructor(
    @Inject(CORE_API_BASE_URL) private readonly apiBaseUrl: string,
    private readonly http: HttpClient,
    private readonly restaurantTableService: RestaurantTableService,
  ) {
    this.getMenuIntegration().pipe(
      untilDestroyed(this),
    ).subscribe();
  }

  public getCategory(hierarchyId: number): Observable<any> {
    const config = this.config$.getValue();
    const url = `@api_host/proxy/${ config.proxyApiProvider }/${ config.apiBasePath }?storeNumber=${ config.storeNumber }&hierarchyID=${ hierarchyId }&priceType=${ config.priceType }&lang=${ config.lang }`;
    return this.http.get(url).pipe(
      map((response: any) => response.response?.responseContent?.categories || []),
    );
  }

  public getImgUrl(sku: number): string {
    const config = this.config$.getValue();
    return `${ this.apiBaseUrl }/proxy/${ config.proxyCdnProvider }/static_images/kelseys2x/750x564/${ sku }@2x.jpg?impolicy=imgopt&imwidth=400`;
  }

  public getImg(sku: number): Observable<Blob> {
    return this.http.get(this.getImgUrl(sku), { responseType: 'blob' });
  }

  private getMenuIntegration(): Observable<void> {
    return this.restaurantTableService.table$.pipe(
      tap((table) => {

        if (table?.integrations?.length) {
          const integration: IIntegrationKelseyMenu = table.integrations.find(
            int => int.provider === 'kelseysMenu',
          ) as IIntegrationKelseyMenu;

          if (integration) {
            this.config$.next({
              apiBasePath: integration.credentials.apiBasePath,
              storeNumber: integration.credentials.storeNumber,
              lang: integration.credentials.lang,
              priceType: integration.credentials.priceType,
              proxyApiProvider: integration.credentials.proxyApiProvider,
              proxyCdnProvider: integration.credentials.proxyCdnProvider,
            });
            this.globalCategories$.next(integration.credentials.categories);
          }
          else {
            this.globalCategories$.next([]);
            this.resetConfig();
          }
        }
        else {
          this.globalCategories$.next([]);
          this.resetConfig();
        }
      }),
      switchMap(() => of(void 0)),
      catchError(err => {
        console.error(err);
        this.globalCategories$.next([]);
        this.resetConfig();
        return of(void 0);
      }),
    );
  }

  private resetConfig(): void {
    this.config$.next({
      apiBasePath: '',
      storeNumber: 0,
      lang: '',
      priceType: '',
      proxyApiProvider: '',
      proxyCdnProvider: 'kelseys-menu-cdn',
    });
  }
}
