import { DBConfig } from 'ngx-indexed-db';
import { databaseNamePrepare } from '@core/core.database';

export const appDatabase: DBConfig = {
  name: databaseNamePrepare('excuse-me.kelseys.offline'),
  isDefault: false as const,
  version: 1,
  objectStoresMeta: [
    {
      store: 'categories',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'categoryName', keypath: 'categoryName', options: { unique: false } },
        { name: 'subCategories', keypath: 'subCategories', options: { unique: false } },
        { name: 'hierarchyId', keypath: 'hierarchyId', options: { unique: false } },
      ],
    },
    {
      store: 'subCategories',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'categoryName', keypath: 'categoryName', options: { unique: false } },
        { name: 'parentCategoryId', keypath: 'parentCategoryId', options: { unique: false } },
      ],
    },
    {
      store: 'products',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: true } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'sku', keypath: 'sku', options: { unique: false } },
        { name: 'showImage', keypath: 'showImage', options: { unique: false } },
        { name: 'productPrice', keypath: 'productPrice', options: { unique: false } },
        { name: 'isVegetarian', keypath: 'isVegetarian', options: { unique: false } },
        { name: 'description', keypath: 'description', options: { unique: false } },
        { name: 'calories', keypath: 'calories', options: { unique: false } },
        { name: 'allergenList', keypath: 'allergenList', options: { unique: false } },
        { name: 'upgrades', keypath: 'upgrades', options: { unique: false } },
        { name: 'upsellItem', keypath: 'upsellItem', options: { unique: false } },
        { name: 'customFields', keypath: 'customFields', options: { unique: false } },
        { name: 'parentSubCategoryId', keypath: 'parentSubCategoryId', options: { unique: false } },
        { name: 'hierarchyId', keypath: 'hierarchyId', options: { unique: false } },
        { name: 'volume', keypath: 'volume', options: { unique: false } },
      ],
    },
  ],
};
