<div class="interacts-present" *ngIf="showInteractsPresent">
  <ng-lottie
    [options]="{path: '/app/animations/touch.json'}"
  ></ng-lottie>
</div>
<div class="interacts-present-bottom"
     *ngIf="!hideTabBar"
>
  <span class="jump">Touch the screen to interact</span>
  <div class="interacts-present-bottom-line"></div>
</div>
<div
  class="tab-bar tab-bar__with-bg"
  [class.hide]="!hideTabBar"
>
  <div class="tabs" #tabs coreActivitySection="TabBar">
    <button
      class="tab"
      [class.underline]="isMenuPage"
      [routerLink]="isMenuPage ? '/main/home' : '/main/menu'"
      routerLinkActive="active"
      [coreUserActivityClickTracking] = "isMenuPage ? 'MenuClose' : 'MenuOpen'"
    >
      <div class="box">
        <div class="icon">
          <img src="/images/tabbar_menu.svg" alt=""/>
        </div>
        <div class="label" [class.active]="isMenuPage">
          <span>{{ 'tabBar.menu' | translate }}</span>
        </div>
      </div>
    </button>

    <button class="tab logo"
            [class.underline]="isServicePage"
            [routerLink]="isHomePage ? '/main/home/service' : '/main/home'"
            routerLinkActive="active"
            [coreUserActivityClickTracking] = "isHomePage ? 'ServicePageOpen' : 'PageClose'"
    >
      <div class="box">
        <div class="icon">
          <img class="logoImg" src="/images/logo.svg" alt=""/>
        </div>
        <div class="label" [class.active]="isServicePage">
          <span>{{ 'tabBar.dashboard' | translate }}</span>
        </div>
      </div>
    </button>

    <button
      class="tab"
      [class.underline]="isSpecialPage"
      [routerLink]="isSpecialPage ? '/main/home' : '/main/special'"
      routerLinkActive="active"
      [coreUserActivityClickTracking] = "isSpecialPage ? 'SpecialPageClose' : 'SpecialPageOpen'"
    >
      <div class="box">
        <div class="icon">
          <img src="/images/special.svg" alt=""/>
        </div>
        <div class="label" [class.active]="isSpecialPage">
          <span>{{ 'tabBar.special' | translate }}</span>
        </div>
      </div>
    </button>
  </div>
</div>
