import { ChangeDetectionStrategy, Component, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { ActivitySectionDirective, Menu, MenusService, UserActivityClickTrackingDirective } from '@core';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { PinchZoomComponent } from '@meddv/ngx-pinch-zoom';

@Component({
  selector: 'app-special-offer',
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.scss'],
  imports: [
    TranslateModule,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
    PinchZoomComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialOfferComponent {

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  public activeMenu: WritableSignal<Menu | undefined> = signal<Menu | undefined>(undefined);

  public readonly specialMenus = toSignal(this.menus.getMenuForCurrentMedia().pipe(
    tap((menus) => {
      if (!menus) {
        this.router.navigate(['main/home']);
      }
      if (menus && menus.length === 1) {
        this.activeMenu.set(menus[0])
      }
    }),
  ));

  constructor(
    private readonly router: Router,
    private readonly menus: MenusService,
  ) {}

}
