import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  CORE_FEATURE_TOGGLE,
  CoreFeatureToggle,
  NetworkService,
  RestaurantTableService,
  WebsocketService,
  WidgetUiConfigService,
} from '@core';
import { KelseysSectionsGridComponent } from '@app/components/kelseys-sections-grid/kelseys-sections-grid.component';

@Component({
  selector: 'app-service-centre',
  standalone: true,
  templateUrl: './service-centre.component.html',
  imports: [
    NgIf,
    KelseysSectionsGridComponent,
    TranslateModule,
    ActivitySectionDirective,
  ],
  styleUrls: ['./service-centre.component.scss'],
})
export class ServiceCentreComponent implements OnInit {

  tableName: string | null = null;
  wifiName: string | null = null;
  wifiPassword: string | null = null;

  constructor(
    @Inject(CORE_FEATURE_TOGGLE) private readonly featureToggle: CoreFeatureToggle,
    private readonly destroyRef: DestroyRef,
    private readonly translate: TranslateService,
    private readonly webSocketService: WebsocketService,
    private readonly restaurantTableService: RestaurantTableService,
    private readonly widgetUiConfigService: WidgetUiConfigService,
    private readonly network: NetworkService,
  ) {}

  get widgetUi(): WidgetUiConfigService {
    return this.widgetUiConfigService;
  }

  get withCallWaiter(): boolean {
    return this.featureToggle.withCallWaiter && (
      this.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterFeatures(): boolean {
    const withCallWaiter = this.restaurantTableService.table$.getValue()?.features?.withCallWaiter;

    if (withCallWaiter !== undefined) {
      return withCallWaiter;
    }

    return true;
  }

  get withCallWaiterToRepeat(): boolean {
    return this.featureToggle.withCallWaiterToRepeat && (
      this.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get withCallWaiterToPay(): boolean {
    return this.featureToggle.withCallWaiterToPay && (
      this.featureToggle.withCallWaiterOnOffline
      || this.isOnline && this.isWebSocketConnected
    );
  }

  get isOnline(): boolean {
    return this.network.isOnline;
  }

  get isWebSocketConnected(): boolean {
    return this.webSocketService.isConnected;
  }

  get callWaiter(): boolean {
    return this.restaurantTableService.callWaiter$.getValue();
  }

  get callWaiterToPay(): boolean {
    return this.restaurantTableService.callWaiterToPay$.getValue();
  }

  get callWaiterToRepeat(): boolean {
    return this.restaurantTableService.callWaiterToRepeat$.getValue();
  }

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  ngOnInit(): void {
    this.restaurantTableService.table$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((tableInfo) => {
      this.tableName = tableInfo?.tableName ?? null;
      this.wifiName = tableInfo?.wifiName ?? null;
      this.wifiPassword = tableInfo?.wifiPassword ?? null;
    });
  }

  public changeLanguage(language: string): void {
    this.translate.use(language);
  }

  handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiter$.next(!this.callWaiter);
    }
  }

  handlerCallWaiterToPay($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToPay$.next(!this.callWaiterToPay);
    }
  }

  handlerCallWaiterToRepeat($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      this.restaurantTableService.callWaiterToRepeat$.next(!this.callWaiterToRepeat);
    }
  }

}
