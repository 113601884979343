import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISectionData } from '../kelseys-sections-grid/kelseys-sections-grid.component';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-kelseys-more-section',
  standalone: true,
  templateUrl: './kelseys-more-section.component.html',
  imports: [
    NgStyle,
    NgIf,
    NgForOf,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./kelseys-more-section.component.scss'],
})
export class KelseysMoreSectionComponent {

  @Output() backToSections = new EventEmitter<boolean>();
  @Input() data!: ISectionData;

  public back(): void {
    this.backToSections.emit(true);
  }

}
