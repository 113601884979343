import { Routes } from '@angular/router';
import { MainPageComponent } from './pages';
import { MenuTabComponent } from './pages/main-page/menu-tab/menu-tab.component';
import { HomeTabComponent } from './pages/main-page/home-tab/home-tab.component';
import { SpecialOfferComponent } from './pages/main-page/special-offer/special-offer.component';
import { ServiceCentreComponent } from './pages/main-page/service-centre/service-centre.component';
import { SpecialTabComponent } from './pages/main-page/special-tab/special.component';

export const appRoutes: Routes = [
  {
    path: 'main',
    component: MainPageComponent,
    children: [
      {
        path: 'menu',
        component: MenuTabComponent,
        data: { animation: 'PlayTab', screen: 'Menu' },
      },
      {
        path: 'home',
        component: HomeTabComponent,
        data: { animation: 'HomeTab', screen: 'Home' },
        children: [
          {
            path: 'service',
            component: ServiceCentreComponent,
            data: { animation: 'ServiceCentre', screen: 'ServiceCentre' },
          },
          {
            path: 'special-offer',
            component: SpecialOfferComponent,
            data: { animation: 'SpecialOffer', screen: 'SpecialOffer' },
          },
        ],
      },
      {
        path: 'special',
        component: SpecialTabComponent,
        data: { animation: 'MoreTab', screen: 'Special' },
      },
    ],
  },
];
